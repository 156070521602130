"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldPolyfill = void 0;
function supportedLocalesOf(locale) {
    if (!locale) {
        return true;
    }
    var locales = Array.isArray(locale) ? locale : [locale];
    return Intl.PluralRules.supportedLocalesOf(locales).length === locales.length;
}
function shouldPolyfill(locale) {
    return (!('PluralRules' in Intl) ||
        new Intl.PluralRules('en', { minimumFractionDigits: 2 }).select(1) ===
            'one' ||
        !supportedLocalesOf(locale));
}
exports.shouldPolyfill = shouldPolyfill;
